const tw={
    jiantizhongwen:'繁體中文',
    xianlu:'線路',
    dianwei:'當前點位',
    wodezichan:'我的資產',
    touzikuaixun:'投資資訊',
    jiaoyiyonghu:'交易用戶',
    jiaoyipinglei:'交易品類',
    danlunhuoli:'單輪獲利',
    jiaoyiriqi:'交易日期',
    dengluhouchakan:'登陸後查看',
    shouye:'首頁',
    jiaoyidating:'購入大廳',
    yucun:'預存',
    zoushi:'走勢',
    wode:'我的',
    zuiditouzi:'最低投資',
    zuidijinru:'最低進入',
    jichulicai:'基礎公益',
    zishenlicai:'資深公益',
    dashilicai:'大師公益',
    zhuanjialicai:'專家公益',
	基礎公益:'基礎公益',
    資深公益:'資深公益',
    大師公益:'大師公益',
    專家公益:'專家公益',
    資深理財: '資深理財',
    請聯絡客服匹配儲值帳號: '請聯絡客服匹配儲值帳號',
    請聯絡客服匹配儲值地址: '請聯絡客服匹配儲值地址',
    huiyuanquanyi:'會員權益',
    zuoriyingli:'昨日盈利',
    zongzichan:'總資產',
    chucunjin:'儲存金',
    jinriyingli:'今日盈利',
    zhuanchu:'提领',
    cunru:'储值',
    zhanghuxinxni:'帳戶訊息',
    lishizhangdan:'歷史帳單',
    zijinminxi:'資金明細',
    huodonggonggao: '活動公告',
    renwuzhongxin:'任務中心',
    xiaoxigonggao:'訊息公告',
    tuijianfenxiang:'推薦分享',
    huodongzhongxin:'活動中心',
    jibenziliao:'基本資料',
    dengluzhanghao:'登錄帳號',
    nicheng:'昵称',
    shezhi:'設置',
    genggaixianlu:'更改線路',
    guanyuwomen:'關於我們',
    xitongshezhi:'系統設置',
    yuyanshezhi:'語言設置',
    tuichudenglu:'退出登錄',
    bangdingyinhangka:'綁定銀行帳號',
    bangdingxuniqianbao:'綁定虛擬錢包',
    anquanma:'提領密碼',
    xiugailenglumima:'修改登錄密碼',
    yinhangkaguanli:'銀帳號管理',
    tianjiayinhangka:'添加銀帳號',
    xuniqianbaoguanli:'虛擬錢包管理',
    tianjiaxuniqianbao:' 添加虛擬錢包',
    chiakren:'持卡人',
    yinhangkakahao:'銀行帳號',
    yinhangmingcheng:'銀行名稱',
    yhkTps:'請綁定持卡人本人的銀行帳號',
    nixiqianbaodizhi:'虛擬錢包地址',
    xuniqianbaoleixing:'虛擬錢包類型',
    xnqbTps:' 溫馨提示：請填寫USDT地址類型，如：trc20、erc20、omni。',
    plchikaren:'請輸入持卡人姓名',
    plkahao:'請輸入正確的銀行帳號',
    plyinhangmingcheng:'請輸入銀行名稱',
    plqbdz:'請輸入虛擬錢包地址',
    plqblx:'請輸入虛擬錢包類型',
    shezhianquanma:'重置安全碼',
    yuananquanma:'原安全碼',
    xinanquanma:'新安全碼',
    planquanma:'請輸入原安全碼',
    plxinanquanma:'請輸入新安全碼',
    queren:'確認',
	quxiao:'取消',
    anquanmaTps:'請設置安全碼，請勿與銀行帳號密碼相同',
    anquanmaTpsTow:'尊敬的客戶，此安全碼是您轉出時的密碼，為了安全起見，請盡量不要與登錄密碼相同',
    xiugaidenglu:'修改登錄密碼',
    jiumima:'舊密碼',
    xinmima:'新密碼',
    querenmima:'確認密碼',
    pljiumima:'請輸入舊密碼',
    plxinmima:'請輸入新密碼',
    plquerenmima:'請再次輸入密碼',
    wancheng:'完成',
    kaishitouzi:'開始購入',
    kaiTps:'當前線路無法使用時，可嘗試切換其他線路',
    zuiyou:'最優',
    dangaqianxianlu:'當前線路',
    dangqianbanben:'當前版本',
    banquansuoyou:'版權所有',
    yinglizhendong:'盈利震動',
    gensuixitong:'跟隨系統',
    zhuanchujine:'轉出金額',
    plzhuanchu:'請輸入轉出金額',
    zhuanchuTps:'如有疑問請聯系客服',
    xuanzeyh:'請選擇提款銀帳號',
    xuanzehb:'請選擇提款虛擬錢包',
    tijiaoshenqing:'提交申請',
    shuruanquanma:'請輸入提領密碼',
    xianshangcunru:'線上存入',
    saomacunru:'USDT',
    yhcode:'銀行代碼',
    fhname:'分行名稱',
    pyhcode:'请输入銀行代碼',
    pfhname:'请输入分行名稱',
    yinhang:'銀行',
    xingming:'姓名',
    kahao:'帳號',
    wangyincunru:"網銀存入",
    shijian:'時間',
    upshijian:'開始時間',
    downshijian:'結束時間',
    wuTps:'暫無相關數據~',
    jiazaicx:'重新加載',
    di:'第',
    lun:'輪',
    weiyingli:'未盈利',
    yishouli:'已受理',
    zhuangtai:'狀態',
    chongzhi:'重置',
    riqi:'日期',
    kefu: '客服',
    youjiankefu: '郵件客服',
    qianwanggouru: '前 往 購 入',
    tongzhi: '活動：使用USDT儲值本平台贈送儲值金額的10%',
    xianbangding: '提示：請先綁定虛擬錢包再綁定銀行帳號',
    hdgg1: '本平台董事會決議開辦定期定額業務。',
    hdgg2: '開辦方式：',
    hdgg3: '预约存儲 10万 领取5% 储蓄金5000.00',
    hdgg4: '预约存儲 20万 领取6% 储蓄金12000.00',
    hdgg5: '预约存儲 30万 领取8% 储蓄金24000.00',
    hdgg6: '预约存儲 40万 领取10% 储蓄金40000.00',
    hdgg7: '预约存儲 50万 领取12% 储蓄金60000.00',
    hdgg8: '预约存儲 100万 领取15% 储蓄金150000.00',
    hdgg9: '预约存儲 200万以上 领取20% 储蓄金400000.00',
    hdgg10: '办理成功後每月会赠送您预约对应的储蓄金。（結算日為每月30號）',
    hdgg11: '【定存期限為2個月一期，到期後用戶可以提領本金和定存收益】【未到期前提領視為放棄，定存收益歸零，本金不變】',
    CapActive:{
        chongzhi:'儲值',
        tixian:'提領',
        goumai:'購買',
        yingli:'購買',
        zengsong:'贈送',
        weitongguo:'未通過',
        yitongguo:'已通過',
        yijujue:'已拒絕',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平臺公告',
    fuzhilanjie:"復制鏈接",
    denglu:'登 錄',
    jizhumima:'記住密碼',
    wangjimima:'忘記密碼',
    youkedenglu:'遊客登錄',
    zhucezhanghao:"前 往 註 冊",
    plusername:'請輸入手機號或郵箱',
    plpassword:'請輸入密碼',
    wanchengzhuce:'完 成 註 冊',
    yiyouzhanghao:'已有帳號',
    qingdenglu:'請登錄',
    zhuce:'註冊',
    remenhuati:'熱門話題',
    news1:'原創',
    news2:'推薦',
    news3:'財富',
    renliulan:'人瀏覽',
    hezhi:'和值',
    lunshu:'輪數',

    做多:'做多',
    做空:'做空',
    多单:'多單',
    多双:'多雙',
    空单:'空單',
    空双:'空雙',
    平单:'平單',
    平双:'平雙',
	极阴:'極陰',
	极阳:'極陽',
    qi:'輪',
    juli:'距離',
    lunjiezhi:'輪截至',
    yifengpan:'已封盤',
    yiguanbi:'已關閉',
    yonghu:'用戶',
    danlunjiaoyi:'單輪交易',
    caozuo:'操作',
    pljoin:'加入聊天室成功',
    pltingshou:'產品已停售',
    gengou:"跟購",
	quedinggengou:"確定跟購嗎",
	wanjia:"玩家",
	leibie:"類別",
	jine:"金額",
    gouru:'購入',
    zanweigouru:'暫未購入',
    qigou:'起購',
    gourushuliang:'購入金額',
    changgui:'常規',
    shuzi:'數位',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介紹',
    lijicunru:'立即存入',
    kaihaojieguo:'開號結果',
    kaihaoqushi:'開號趨勢',
    chanpingshuoming:'產品說明',
    qingchuhuancun:'清除緩存',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'下單成功',
	jiazaizhong:'加載中...',
	guanbitg:'已關閉，停止購入',
	xiangoudl:'只能跟購當前輪',
	liaotiantip:'系統已禁言，請選擇購入',
	tishi:'提示',
	zhidao:'知道了',
	zhuanchusb:'轉出失敗',
	zhuanchusbs:'餘額不足，轉出失敗',
	buyxq:'購入詳情',
	orderNo:'帳單編號',
	plan_name:'廳房訊息',
	buyxx:'購入訊息',
	haoxx:'選號詳情',
	buysj:'購入時間',
	yilou:'遺漏',
	fzjqb:'已復製到粘貼板',
	chanpin1:'開售時間：每3.5分鐘為一輪，當日10:00AM開盤，次日02:00AM停盤。 ',
	chanpin2:'每輪有3個0-9的數字，3個數字相加為最終結果，可以投資結果做多做空平單平雙極陽極陰以及數字。 ',
	Tips:{
        wjmm:'忘記密碼請聯繫客服修改!',
        dlcg:'登錄成功',
        tjcg:'提交成功',
        zanwu:'暫無數據',
        buyizhi:'兩次密碼不一致',
        zcbz:'您的資產不足請存入',
        pltxje:'請輸入提現金額',
        zdtx:'最低提現100',
		youke:'尊敬的遊客您好：請您註冊正式會員後操作。 ',
		szcg:'設置成功',
    },
}
export default tw;
